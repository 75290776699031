import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from '../SchoolProgram2023/components';


const Christmas2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Christmas/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Christmas/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Christmas/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Christmas/4.webp`;
const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Christmas/5.webp`;
const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Christmas/6.webp`;
const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Christmas/7.webp`;
const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Christmas/9.webp`;
const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Christmas/10.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
          src: p6,
          source: p6,
          rows: 1,
          cols: 1,
      },
        {
            src: p2,
            source: p2,
            rows: 1.2,
            cols: 2,
        },
        {
          src: p3,
          source: p3,
          rows: 1,
          cols: 2,
      },
        {
          src: p7,
          source: p7,
          rows: 1,
          cols: 1,
      },
        // {
        //     src: p8,
        //     source: p8,
        //     rows: 1,
        //     cols: 1,
        // },
        {
          src: p9,
          source: p9,
          rows: 1,
          cols: 1,
     },
     {
        src: p5,
        source: p5,
        rows: 1,
        cols: 2,
    },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 2,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                CHRISTMAS
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: Pre-Primary and 1-12  Date: 22 DECEMBER 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School celebrated Christmas on Friday 22 December 2023 to spread joy and happiness of the season.
                                    <br></br>
                                    The students of Class 1 sang melodious carols to bring out the aura of the festival alive on the stage.  
                                    <br></br>
                                    The students of Class 2 moved their feet and hands to the beats of Christmas bells. It made the audience swing and sway with smiles.
                                    <br></br>
                                    The Senior choir sang meaningful carols in synchronized tunes which brought out the essence of creating a harmonious community.
                                    <br></br>
                                    The students of Pre-Primary participated in the festivities by adorning Santa caps and relishing Christmas treats which they carried as part of their snack for the day.
                                    <br></br>
                                    The event was culminated with the arrival of Santa Claus which made the students enthusiastic and happy. The celebration reinstated the value of caring for everyone and implanted the thought of sharing in the young minds .The joy radiated and filled the air while children learnt to find happiness while making others happy.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Christmas is the season for kindling the fire of hospitality.”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Christmas2023;